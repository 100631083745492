<template>
	<div class="detail">
		<Header title="评论详情" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<!-- <a-form ref="formRef" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="用户名称" name="nickname" class="ui-form__item"s>
						<a-input v-model:value="formState.nickname"></a-input>
					</a-form-item>
					
					<a-form-item label="用户手机" name="phone" class="ui-form__item"s>
						<a-input v-model:value="formState.phone"></a-input>
					</a-form-item>
					
					<a-form-item label="评论时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>
			</a-form> -->
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.nickname || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
						</template>
						
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						
						<template v-if="column.key === 'content'">
							<div ref="content" class="ui-from__content">{{ record.content }}</div>
							<span v-show="record.showContent" class="ui-form__show">查看全文</span>
						</template>
			
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getReplyList, deleteReply } from '@/service/modules/comment.js';
	export default {
		name: "evaluateDetail",
		components: {
			Icon,
			Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				time: [],
				formState: {},
				searchData: {},
				columns: [{
					title: '用户信息',
					key: 'userInfo',
					width: 100
				}, {
					title: '评论日期',
					key: 'time',
					width: 100
				}, {
					title: '评论内容',
					dataIndex: 'comment',
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 80
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			};
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			reset() {
				this.time = [];
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getReplyList({
					id: this.id,
					page: this.pagination.current,
					pageSize: this.pagination.pageSize
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.replyList.list;
					this.pagination.total = ret.data.replyList.totalCount;
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async()=> {
						this.loading = true;
						let ret = await deleteReply({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功')
							this.getData();
						}
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.detail {
		.ui-title {
			position: relative;
			margin: 20px 0 10px;
			padding-left: 10px;
			font-size: 16px;
			font-weight: 600;
		}

		.ui-title::before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			width: 4px;
			height: 20px;
			border-radius: 2px;
			background-color: #1890ff;
			transform: translateY(-50%);
		}
	}
</style>