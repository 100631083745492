<template>
	<div>
		<Header title="评价详情" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="formState" name="form">
				<!-- 搜索栏 -->
				<a-row>

					<a-form-item label="用户名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入用户名称"></a-input>
					</a-form-item>

					<a-form-item label="用户手机" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入用户手机"></a-input>
					</a-form-item>

					<a-form-item label="所属戏剧" name="program" class="ui-form__item">
						<a-input v-model:value="formState.program" placeholder="请输入所属戏剧"></a-input>
					</a-form-item>

					<a-form-item label="戏剧类型" class="ui-form__item" name="type">
						<a-select v-model:value="formState.type" placeholder="请选择戏剧类型" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">影片</a-select-option>
							<a-select-option :value="2">演出</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="评论日期" name="time" class="ui-form__item">
						<a-range-picker v-model:value="formState.time"></a-range-picker>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="24" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
					:scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">

						<template v-if="column.key === 'content'">
							<div ref="content" class="ui-from__content">{{ record.content }}</div>
							<span @click="onEdit(record.content)" v-if="record.content.length > 34"
								class="ui-form__label">查看全文</span>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div><a-menu-item>删除</a-menu-item></div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

			<a-modal title="详情" v-model:visible="editModalVisible" @ok="onEditOk">
				<a-spin :spinning="loading">

					<div class="detail">

						<div>
							<h3 class="ui-title">用户信息</h3>
							<a-descriptions style="margin-top: 30px">
								<a-descriptions-item label="用户名称">{{ userInfo.name }}</a-descriptions-item>
								<a-descriptions-item label="日期">{{ userInfo.time }}</a-descriptions-item>
								<a-descriptions-item label="评分">{{ userInfo.score }}</a-descriptions-item>
							</a-descriptions>
						</div>

						<div>
							<h3 class="ui-title">评价内容</h3>
							<a-textarea style="margin-top: 20px;" v-model:value="content"></a-textarea>
						</div>

					</div>

				</a-spin>
			</a-modal>

		</a-spin>

	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	export default {
		components: {
			Icon,
			Header
		},
		data() {
			return {
				showAll: false,
				content: "啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬啥都反对非法思念对方阿萨的奶粉ask的烦恼阿斯蒂芬",
				userInfo: {
					name: "用户名称",
					time: "2023-05-05",
					score: "9分",
				},
				isEdit: false,
				editModalVisible: false,
				loading: false,
				formState: {

				},
				columns: [{
					title: '用户',
					dataIndex: 'user',
					width: 100
				}, {
					title: '手机号码',
					dataIndex: 'phone',
					width: 120
				}, {
					title: '戏剧类型',
					dataIndex: 'type',
					width: 80
				}, {
					title: '所属戏剧',
					dataIndex: 'program',
					width: 100
				}, {
					title: '评论日期',
					dataIndex: 'time',
					width: 140
				}, {
					title: '内容',
					dataIndex: 'content',
					key: 'content',
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 80
				}],
				list: [{
					key: 1,
					user: '用户名',
					phone: '17772290001',
					type: '影片',
					program: '《影片名称》',
					time: '2023.1.1 12:12:12',
					content: '内容内容内容内容内容内容内容内容内容内容内容内容容内容内容',
					showContent: true
				}, {
					key: 2,
					user: '用户名',
					phone: '17772290001',
					type: '影片',
					program: '《影片名称》',
					time: '2023.1.1 12:12:12',
					content: '内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容',
					showContent: true
				}, {
					key: 3,
					user: '用户名',
					phone: '17772290001',
					type: '演出',
					program: '《演出名称》',
					time: '2023.1.1 12:12:12',
					content: '内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容',
					showContent: true
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {},
		methods: {
			reset() {
				this.$refs.form.resetFields();
			},
			onEditOk() {
				console.log("编辑完成");
			},
			onEdit(str) {
				this.isEdit = true;
				this.editModalVisible = true;
				this.content = str;
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	.ui-from__content {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* 控制行数 */
		-webkit-box-orient: vertical;
	}

	.ui-form__label {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		color: #65bde3;
		cursor: pointer;
	}

	.ui-title {
		margin: 20px 0 10px;
		padding-left: 10px;
		font-size: 16px;
		font-weight: 600;
		position: relative;
	}

	.ui-title::before {
		content: "";
		width: 4px;
		height: 20px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: #1890ff;
		border-radius: 2px;
	}
</style>